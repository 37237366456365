@include govuk-exports("govuk/core/lists") {
  %govuk-list {
    @include govuk-font($size: 19);
    @include govuk-text-colour;
    margin-top: 0;
    @include govuk-responsive-margin(4, "bottom");
    padding-left: 0;
    list-style-type: none;

    // Add a top margin for nested lists
    %govuk-list {
      margin-top: govuk-spacing(2);
    }
  }

  %govuk-list > li {
    // Lists without numbers or bullets should always have extra space between
    // list items. Lists with numbers or bullets only have this extra space on
    // tablet and above
    margin-bottom: govuk-spacing(1);
  }

  .govuk-list {
    @extend %govuk-list;
  }

  %govuk-list--bullet {
    padding-left: govuk-spacing(4);
    list-style-type: disc;
  }

  %govuk-list--number {
    padding-left: govuk-spacing(4);
    list-style-type: decimal;
  }

  %govuk-list--bullet > li,
  %govuk-list--number > li {
    margin-bottom: 0;

    @include govuk-media-query($from: tablet) {
      margin-bottom: govuk-spacing(1);
    }
  }

  %govuk-list--spaced > li {
    margin-bottom: govuk-spacing(2);

    @include govuk-media-query($from: tablet) {
      margin-bottom: govuk-spacing(3);
    }
  }

  .govuk-list--bullet {
    @extend %govuk-list--bullet;
  }

  .govuk-list--number {
    @extend %govuk-list--number;
  }

  .govuk-list--spaced {
    @extend %govuk-list--spaced;
  }
}
