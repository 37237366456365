////
/// @group helpers/layout
////

/// Clear floated content within a container using a pseudo element
///
/// @access public

@mixin govuk-clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
