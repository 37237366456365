:root {
  // This variable is automatically overwritten during builds and releases.
  // It doesn't need to be updated manually.
  --govuk-frontend-version: "development";

  // CSS custom property for each breakpoint
  @each $name, $value in $govuk-breakpoints {
    --govuk-frontend-breakpoint-#{$name}: #{govuk-px-to-rem($value)};
  }
}
