@import "../../core/lists";

@include govuk-exports("govuk/component/error-summary") {
  .govuk-error-summary {
    @include govuk-font($size: 19);
    @include govuk-text-colour;
    @include govuk-responsive-padding(4);
    @include govuk-responsive-margin(8, "bottom");

    border: $govuk-border-width solid $govuk-error-colour;

    &:focus {
      outline: $govuk-focus-width solid $govuk-focus-colour;
    }
  }

  .govuk-error-summary__title {
    @include govuk-font-size($size: 24);
    @include govuk-typography-weight-bold;

    margin-top: 0;
    @include govuk-responsive-margin(4, "bottom");
  }

  .govuk-error-summary__body {
    p {
      margin-bottom: 0;
    }

    > * + * {
      @include govuk-responsive-margin(4, "top");
    }
  }

  // Cross-component class - adjusts styling of list component
  .govuk-error-summary__list {
    margin-bottom: 0;
  }

  // Remove the bottom margin from the last list item
  .govuk-error-summary__list li:last-child {
    margin-bottom: 0;
  }

  .govuk-error-summary__list a {
    @include govuk-typography-weight-bold;
    @include govuk-link-common;
    @include govuk-link-style-error;
  }
}
