@include govuk-exports("govuk/component/skip-link") {
  .govuk-skip-link {
    @include govuk-visually-hidden-focusable;
    @include govuk-typography-common;
    @include govuk-link-decoration;
    @include govuk-link-style-text;
    @include govuk-font-size($size: 16);

    display: block;
    padding: govuk-spacing(2) govuk-spacing(3);

    // Respect 'display cutout' safe area (avoids notches and rounded corners)
    @supports (padding: unquote("max(calc(0px))")) {
      $padding-safe-area-right: calc(#{govuk-spacing(3)} + env(safe-area-inset-right));
      $padding-safe-area-left: calc(#{govuk-spacing(3)} + env(safe-area-inset-left));

      // Use max() to pick largest padding, default or with safe area
      // Escaped due to Sass max() vs. CSS native max()
      padding-right: unquote("max(#{govuk-spacing(3)}, #{$padding-safe-area-right})");
      padding-left: unquote("max(#{govuk-spacing(3)}, #{$padding-safe-area-left})");
    }

    &:focus {
      outline: $govuk-focus-width solid $govuk-focus-colour;
      outline-offset: 0;
      background-color: $govuk-focus-colour;

      // Undo unwanted changes when global styles are enabled
      @if $govuk-global-styles {
        @include govuk-link-decoration;
        box-shadow: none;
      }
    }
  }

  .govuk-skip-link-focused-element {
    &:focus {
      // Remove the native visible focus indicator when the element is programmatically focused.
      //
      // We set the focus on the linked element (this is usually the <main> element) when the skip
      // link is activated to improve screen reader announcements. However, we remove the visible
      // focus indicator from the linked element because the user cannot interact with it.
      //
      // A related discussion: https://github.com/w3c/wcag/issues/1001
      outline: none;
    }
  }
}
