@import "../error-message/index";
@import "../hint/index";
@import "../label/index";

@include govuk-exports("govuk/component/select") {
  .govuk-select {
    @include govuk-font($size: 19, $line-height: 1.25);

    box-sizing: border-box; // should this be global?

    // This min-width was chosen because:
    // - it makes the Select noticeably wider than it is taller (which is what users expect)
    // - 11.5em matches the 'length-10' variant of the input component
    // - it fits comfortably on screens as narrow as 240px wide
    min-width: 11.5em;
    max-width: 100%;
    height: govuk-px-to-rem(40px);
    padding: govuk-spacing(1); // was 5px 4px 4px - size of it should be adjusted to match other form elements
    border: $govuk-border-width-form-element solid $govuk-input-border-colour;

    // Default user agent colours for selects can have low contrast,
    // and may look disabled (#2435)
    color: $govuk-text-colour;
    background-color: govuk-colour("white");

    &:focus {
      outline: $govuk-focus-width solid $govuk-focus-colour;
      // Ensure outline appears outside of the element
      outline-offset: 0;
      // Double the border by adding its width again. Use `box-shadow` to do
      // this instead of changing `border-width` (which changes element size) and
      // since `outline` is already used for the yellow focus state.
      box-shadow: inset 0 0 0 $govuk-border-width-form-element;
    }

    &:disabled {
      opacity: 0.5;
      color: inherit;
      cursor: not-allowed;
    }
  }

  .govuk-select option:active,
  .govuk-select option:checked,
  .govuk-select:focus::-ms-value {
    color: govuk-colour("white");
    background-color: govuk-colour("blue");
  }

  .govuk-select--error {
    border-color: $govuk-error-colour;

    &:focus {
      border-color: $govuk-input-border-colour;
    }
  }
}
